import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { analytics, auth } from '../services/firebase'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        clients: [],
        users: [],
        tools: [],
        quotes: [],
        pdfImages: null,
        commercialTerms: [],
        historyQuotes: [],
        archivedCosts: [],
        archivedVersions: [],
        historyCosts: [],
        costQuotes: [],
        settings: [],
        contacts: [],
        companyRoles: [],
        usersPermissions: [],
        saveBtn: true,
        totalCost: 0,
        seeMenu: false,
        ready: false,
        userRef: null,
        commercialImages: [],
        tempQuote: null,
        fromMainBoard: false,
        clientQuote: undefined,
        alternatives: [],
        emailBody: [],
        QuotesByCustomizedParameters: [],
        selectedQuoteInGeneral: undefined,
        errorItems: [],
        timeOutId: undefined,
        projectId: undefined,
        route: undefined,
        notificationResource: undefined,
        invoiceUpdates: undefined,
        bomUpdates: undefined,
        woUpdates: undefined,
    },
    mutations: {
        setRoute(state, payload) {
            state.route = payload
        },
        setProjectId(state, payload) {
            state.projectId = payload
        },
        cleanErrors(state) {
            state.errorItems = []
        },
        setTimeOutId(state, payload) {
            state.timeOutId = payload
        },
        updateErrorItems(state, errorId) {
            state.errorItems = state.errorItems.filter(
                error => error.id !== errorId
            )
        },
        setErrorItems(state, payload) {
            const id = uuidv4()
            state.errorItems = [
                ...state.errorItems,
                { id, message: payload.message },
            ]
            analytics.logEvent('catch_error', {
                content: payload.message,
                screen_class: payload.source,
                source: auth().currentUser.uid,
            })
        },
        setSelectedQuoteInGeneral(state, payload) {
            state.selectedQuoteInGeneral = payload
        },
        setQuotesByCustomizedParameters(state, payload) {
            state.QuotesByCustomizedParameters = [...payload]
        },
        setEmailBody(state, payload) {
            state.emailBody = payload
        },
        setAlternatives(state, payload) {
            state.alternatives = payload
        },
        setClientQuote(state, payload) {
            state.clientQuote = payload
        },
        newCommercialImage(state, payload) {
            state.commercialImages.splice(0, 0, payload)
        },
        loadReady: function(state) {
            state.ready = true
        },
        saveUserRef: function(state, payload) {
            state.userRef = payload
        },
        setCommercialImages(state, payload) {
            state.commercialImages[payload.index] = payload.image
        },
        addCommercialImage(state, payload) {
            state.commercialImages.push(payload)
        },
        delCommercialImage(state, payload) {
            state.commercialImages.splice(payload, 1)
        },
        updateSeeMenu(state, payload) {
            state.seeMenu = payload
        },
        setFromMainBoard(state, payload) {
            state.fromMainBoard = payload
        },
        resetState(state) {
            state.clients = []
            state.users = []
            state.tools = []
            state.quotes = []
            state.pdfImages = null
            state.commercialTerms = []
            state.historyQuotes = []
            state.archivedCosts = []
            state.archivedVersions = []
            state.historyCosts = []
            state.costQuotes = []
            state.settings = []
            state.contacts = []
            state.companyRoles = []
            state.usersPermissions = []
            state.saveBtn = true
            state.totalCost = 0
            state.seeMenu = false
            state.ready = false
            state.userRef = null
            state.commercialImages = []
            state.tempQuote = null
            state.fromMainBoard = false
            state.clientQuote = undefined
            state.emailBody = []
            state.QuotesByCustomizedParameters = []
            state.projectId = undefined
            state.notificationResource = undefined
        },
        replaceClients(state, payload) {
            const { id, client } = payload
            const index = state.clients.findIndex(u => u.id === id)
            state.clients.splice(index, 1, client)
        },
        updateClient(state, payload) {
            const { id, url } = payload
            const index = state.clients.findIndex(u => u.id === id)
            state.clients[index].profilePicture = url
        },
        replaceUser(state, payload) {
            const { id, user } = payload
            const index = state.users.findIndex(u => u.id === id)
            state.users.splice(index, 1, user)
        },
        replaceQuote(state, payload) {
            const { id, quote } = payload
            const index = state.quotes.findIndex(u => u.id === id)
            state.quotes.splice(index, 1, quote)
            state.quotes = _.cloneDeep(state.quotes)
        },
        updateQuote(state, payload) {
            const { id, quote } = payload
            const index = state.quotes.findIndex(u => u.id === id)
            if (index >= 0) {
                state.quotes.splice(
                    index,
                    1,
                    Object.assign(
                        index >= 0 ? state.quotes[index] : { id },
                        quote
                    )
                )
            } else {
                state.quotes.push(Object.assign({ id }, quote))
            }
        },
        replaceSetting(state, payload) {
            const index = state.settings.findIndex(u => u.id == payload.id)
            state.settings.splice(index, 1, payload)
        },
        removeUser(state, payload) {
            const { id } = payload
            const index = state.users.findIndex(u => u.id === id)
            state.users.splice(index, 1)
        },
        removeQuote(state, payload) {
            const index = state.quotes.findIndex(u => u.id === payload)
            state.quotes.splice(index, 1)
        },
        removeHistoryQuote(state, payload) {
            const index = state.historyQuotes.findIndex(u => u.id === payload)
            state.historyQuotes.splice(index, 1)
        },
        addClients(state, payload) {
            state.clients = payload
        },
        addContacts(state, payload) {
            state.contacts = payload
        },
        addQuotes(state, payload) {
            state.quotes = payload
        },
        addQuote(state, payload) {
            state.quotes.push(payload)
        },
        addHistoryQuotes(state, payload) {
            state.historyQuotes = payload
        },
        addCost(state, payload) {
            const index = state.costQuotes.findIndex(
                cost => cost.idQuote === payload.idQuote
            )
            index < 0
                ? state.costQuotes.push(payload)
                : state.costQuotes.splice(index, 1, payload)
        },
        addArchivedVersions(state, payload) {
            state.archivedVersions.push(payload)
        },
        addArchivedCosts(state, payload) {
            state.archivedCosts.push(payload)
        },
        addHistoryCost(state, payload) {
            const index = state.historyCosts.findIndex(
                element => element.idQuote == payload.idQuote
            )
            if (index >= 0) {
                state.historyCosts.splice(index, 1, payload)
            } else {
                state.historyCosts.push(payload)
            }
        },
        addTools(state, payload) {
            state.tools = payload
        },
        addPdfImages(state, payload) {
            state.pdfImages = payload
        },
        addCommercialTerms(state, payload) {
            state.commercialTerms = payload
        },
        addSettings(state, payload) {
            state.settings = payload
        },
        addUsers(state, payload) {
            state.users = payload
        },
        addCompanyRoles(state, payload) {
            state.companyRoles = payload
        },
        addUsersPermissions(state, payload) {
            state.usersPermissions = payload
        },
        quoteChanges(state, payload) {
            state.saveBtn = payload
        },
        totalCostChanges(state, payload) {
            state.totalCost = payload
        },
        removeClient(state, payload) {
            const index = state.clients.findIndex(u => u.id === payload)
            state.clients.splice(index, 1)
        },
        resetClients(state) {
            state.clients = []
        },
        setTemQuote(state, payload) {
            state.tempQuote = payload
        },
        resetComercialImages(state) {
            state.commercialImages = []
        },
        setNotificationResource(state, payload) {
            state.notificationResource = payload
        },
        setInvoiceUpdates(state, payload) {
            state.invoiceUpdates = payload
        },
        setBomUpdates(state, payload) {
            state.bomUpdates = payload
        },
        setWOUpdates(state, payload) {
            state.woUpdates = payload
        },
    },
    getters: {
        getProjectId(state) {
            return state.projectId
        },
        getTimeOutId(state) {
            return state.timeOutId
        },
        getErrorItems(state) {
            return state.errorItems
        },
        getSelectedQuoteInGeneral(state) {
            return state.selectedQuoteInGeneral
        },
        getClientQuote(state) {
            return state.clientQuote
        },
        getAlternatives(state) {
            return state.alternatives
        },
        getQuotes(state) {
            return state.quotes
        },
        getEmailBody(state) {
            return state.emailBody
        },
        getQuotesByCustomizedParameters(state) {
            return state.QuotesByCustomizedParameters
        },
        getNotificationResource(state) {
            return state.notificationResource
        },
        getInvoiceUpdates(state) {
            return state.invoiceUpdates
        },
        getBomUpdates(state) {
            return state.bomUpdates
        },
    },
    actions: {
        setFromMainBoardAction({ commit }, state) {
            commit('setFromMainBoard', state)
        },
        loadReadyAction({ commit }) {
            commit('loadReady')
        },
        actionSaveUserRef({ commit }, user) {
            commit('saveUserRef', user)
        },
        delCommercialImageAction({ commit }, index) {
            commit('delCommercialImage', index)
        },
        addCommercialImageAction({ commit }, image) {
            commit('addCommercialImage', image)
        },
        newCommercialImageAction({ commit }, image) {
            commit('newCommercialImage', image)
        },
        setCommercialImagesAction({ commit }, images) {
            commit('setCommercialImages', images)
        },
        updateSeeMenuAction({ commit }, status) {
            commit('updateSeeMenu', status)
        },
        resetStateAction({ commit }) {
            commit('resetState')
        },
        replaceClientAction({ commit }, client) {
            commit('replaceClients', client)
        },
        addContactsAction({ commit }, contacts) {
            commit('addContacts', contacts)
        },
        replaceUserAction({ commit }, user) {
            commit('replaceUser', user)
        },
        replaceQuoteAction({ commit }, quote) {
            commit('replaceQuote', quote)
        },
        updateQuoteAction({ commit }, quote) {
            commit('updateQuote', quote)
        },
        removeUserAction({ commit }, id) {
            commit('removeUser', id)
        },
        removeQuoteAction({ commit }, id) {
            commit('removeQuote', id)
        },
        removeHistoryQuoteAction({ commit }, id) {
            commit('removeHistoryQuote', id)
        },
        replaceSettingAction({ commit }, setting) {
            commit('replaceSetting', setting)
        },
        addClientsAction({ commit }, clients) {
            commit('addClients', clients)
        },
        addImagesAction({ commit }, pdfImages) {
            commit('addPdfImages', pdfImages)
        },
        addTermsAction({ commit }, commercialTerms) {
            commit('addCommercialTerms', commercialTerms)
        },
        addQuotesAction({ commit }, quotes) {
            commit('addQuotes', quotes)
        },
        addQuoteAction({ commit }, quote) {
            commit('addQuote', quote)
        },
        addHistoryQuotesAction({ commit }, historyQuotes) {
            commit('addHistoryQuotes', historyQuotes)
        },
        addCostAction({ commit }, costQuotes) {
            commit('addCost', costQuotes)
        },
        addHistoryCostAction({ commit }, historyCosts) {
            commit('addHistoryCost', historyCosts)
        },
        addArchivedCostsAction({ commit }, archivedCosts) {
            commit('addArchivedCosts', archivedCosts)
        },
        addArchivedVersionsAction({ commit }, archivedVersions) {
            commit('addArchivedVersions', archivedVersions)
        },
        addSettingsAction({ commit }, settings) {
            commit('addSettings', settings)
        },
        addUsersAction({ commit }, users) {
            commit('addUsers', users)
        },
        quoteChangesAction({ commit }, saveBtn) {
            commit('quoteChanges', saveBtn)
        },
        totalCostAction({ commit }, totalCost) {
            commit('totalCostChanges', totalCost)
        },
        addToolsAction({ commit }, tools) {
            commit('addTools', tools)
        },
        addRolesAction({ commit }, companyRoles) {
            commit('addCompanyRoles', companyRoles)
        },
        addPermissionsAction({ commit }, usersPermissions) {
            commit('addUsersPermissions', usersPermissions)
        },
        removeClientAction({ commit }, id) {
            commit('removeClient', id)
        },
        resetClientsAction({ commit }) {
            commit('resetClients')
        },
        setTemQuoteAction({ commit }, quote) {
            commit('setTemQuote', quote)
        },
        resetComercialImagesAction({ commit }) {
            commit('resetComercialImages')
        },
        setNotificationResource({ commit }, resource) {
            commit('setNotificationResource', resource)
        },
        setInvoiceUpdates({ commit }, data) {
            commit('setInvoiceUpdates', data)
        },
        setBomUpdates({ commit }, data) {
            commit('setBomUpdates', data)
        },
    },
    modules: {},
})

<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="costsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[0].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[0].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[0].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{ costsFiltered.length }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>

                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[1].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[1].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[1].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{ milestonesInfo().active }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[2].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[2].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[2].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{ milestonesInfo().completed }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[3].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[3].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[3].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            {{
                                                milestonesInfo().percentage.toFixed(
                                                    1
                                                )
                                            }}
                                            %
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.description`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByDescription"
                    dense
                />
            </template>
            <template v-slot:[`header.quoteNumber`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByQuote"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.description`]="{ item }">
                <div class="d-flex justify-center">
                    <p>
                        {{ item.reference }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.milestone`]="{ item, header }">
                <div class="d-flex justify-center align-center">
                    <p
                        v-if="
                            item.formatMilestones &&
                                item.formatMilestones[header.id]
                        "
                        style="margin: 0;"
                        :class="{
                            'text-gray':
                                dateDiff(
                                    item.formatMilestones[header.id].date
                                ) > 20 &&
                                item.formatMilestones[header.id].percentage <
                                    100,
                            'text-orange':
                                dateDiff(
                                    item.formatMilestones[header.id].date
                                ) > 6 &&
                                dateDiff(
                                    item.formatMilestones[header.id].date
                                ) < 20 &&
                                item.formatMilestones[header.id].percentage <
                                    100,
                            'text-red':
                                dateDiff(
                                    item.formatMilestones[header.id].date
                                ) < 7 &&
                                item.formatMilestones[header.id].percentage <
                                    100,
                            'text-green':
                                item.formatMilestones[header.id].percentage ==
                                100,
                        }"
                    >
                        {{
                            formatDate(
                                item.formatMilestones[header.id].date
                                    ._seconds ||
                                    item.formatMilestones[header.id].date
                                        .seconds
                            )
                        }}
                    </p>
                    <v-btn
                        x-small
                        icon
                        class="d-flex justify-center mt-0 ml-1"
                        @click="openMilestoneDialog(item, header.id)"
                    >
                        <v-icon>
                            {{
                                item.formatMilestones[header.id]
                                    ? item.formatMilestones[header.id]
                                          .percentage == 100
                                        ? 'mdi-eye'
                                        : 'mdi-pencil'
                                    : 'mdi-plus'
                            }}</v-icon
                        >
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--MILESTONE DETAILS-->
        <v-dialog
            v-model="milestoneDialog"
            :retain-focus="false"
            persistent
            width="1300px"
        >
            <MilestoneDetails
                v-if="milestoneDialog"
                :selectedMilestone="selectedMilestone"
                :originalCost="selectedCost"
                :milestones="milestones"
                :settingId="settingId"
                :users="users"
                @replaceMilestones="replaceMilestones"
                @close="closeMilestoneDialog"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'

export default {
    name: 'QuoteMilestones',
    props: {
        project: Object,
        users: Array,
    },
    components: {
        MilestoneDetails: () =>
            import('@/components/Milestones/MilestoneDetails.vue'),
    },
    data: () => ({
        loading: false,
        milestones: [],
        headers: [
            {
                text: 'QUOTE',
                value: 'quoteNumber',
                sortable: false,
                align: 'center',
            },
            {
                text: 'SCOPE',
                value: 'description',
                sortable: false,
                align: 'left',
            },
        ],
        searchByDescription: undefined,
        searchByQuote: undefined,
        milestonesKey: 'milestones',
        selectedCost: {},
        selectedMilestone: null,
        milestoneDialog: false,
        settingId: null,
        costs: [],
        insights: [
            {
                color: '#3498DB',
                icon: 'mdi-format-list-bulleted',
                iconColor: '#1A5276',
                title: 'SCOPES',
            },
            {
                color: '#ffa414',
                icon: 'mdi-flag-triangle',
                iconColor: '#1A5276',
                title: 'MILESTONES',
            },
            {
                color: '#20844c',
                icon: 'mdi-check',
                iconColor: '#2874A6',
                title: 'COMPLETED',
            },
            {
                color: '#F08845',
                icon: 'mdi-percent-outline',
                iconColor: 'primary',
                title: 'PERCENTAGE',
            },
        ],
    }),
    async mounted() {
        try {
            const {
                data: { settings },
            } = await API.getSettings()
            const setting = settings.find(
                setting => setting.name.toLowerCase() == 'quotes'
            )
            if (setting) {
                this.settingId = setting.id
                if (setting[this.milestonesKey]) {
                    this.milestones = setting[this.milestonesKey]
                }
            }

            if (this.milestones && this.milestones.length > 0) {
                this.milestones.forEach(milestone => {
                    this.headers.push({
                        text: milestone.name.toUpperCase(),
                        value: 'milestone',
                        id: milestone.id,
                        sortable: false,
                        align: 'center',
                    })
                })
            }
            //get costs
            const quotes = []
            this.project.quotes.forEach(q =>
                quotes.push({ id: q.split('/')[0], number: q.split('/')[1] })
            )
            for (const quote of quotes) {
                const costs = await API.getCosts(quote.id)
                costs.forEach(c => {
                    c.quoteId = quote.id
                    c.quoteNumber = quote.number
                })
                this.costs.push(...costs)
            }
            this.formatMilestones()
            this.loading = true
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        costsFiltered() {
            const conditions = []
            if (this.searchByDescription) {
                conditions.push(this.filterByDescription)
            }
            if (this.searchByQuote) {
                conditions.push(this.filterByQuote)
            }
            if (conditions.length > 0) {
                return this.costs.filter(cost => {
                    return conditions.every(condition => {
                        return condition(cost)
                    })
                })
            }
            return this.costs
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByDescription(item) {
            return item.reference
                .toLowerCase()
                .includes(this.searchByDescription.toLowerCase())
        },
        filterByQuote(item) {
            return item.quoteNumber
                .toLowerCase()
                .includes(this.searchByQuote.toLowerCase())
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('YYYY/MM/DD')}`
        },
        dateDiff(date) {
            const days = moment.unix(date._seconds).diff(moment(), 'days')
            return days
        },
        openMilestoneDialog(item, milestoneId) {
            this.selectedCost = _.cloneDeep(item)
            this.selectedMilestone = milestoneId
            this.milestoneDialog = true
        },
        closeMilestoneDialog() {
            this.selectedCost = _.cloneDeep({})
            this.selectedMilestone = null
            this.milestoneDialog = false
        },
        replaceMilestones({ costId, milestones }) {
            const costIndex = this.costs.findIndex(c => c.id == costId)
            if (costIndex > -1) {
                this.costs[costIndex].milestones = milestones
                this.formatMilestones()
            }
        },
        formatMilestones() {
            this.costs.forEach(cost => {
                let formatMilestones = {}
                if (cost.milestones) {
                    cost.milestones.forEach(milestone => {
                        if (typeof milestone.date == 'string') {
                            const date = new Date(milestone.date)
                            const _seconds = Math.floor(date.getTime() / 1000)
                            milestone.date = { _seconds }
                        }
                        formatMilestones[milestone.id] = milestone
                    })
                }
                cost.formatMilestones = formatMilestones
            })
        },
        milestonesInfo() {
            let milestonesInfo = { active: 0, completed: 0, percentage: 0 }
            this.costsFiltered.forEach(c => {
                if (c.milestones) {
                    milestonesInfo.active += c.milestones.length
                    milestonesInfo.completed += c.milestones.reduce(
                        (accumulator, milestone) =>
                            accumulator + milestone.percentage == 100 ? 1 : 0,
                        0
                    )
                    milestonesInfo.percentage += c.milestones.reduce(
                        (accumulator, milestone) =>
                            accumulator + milestone.percentage,
                        0
                    )
                }
            })
            if (milestonesInfo.active > 0) {
                milestonesInfo.percentage =
                    milestonesInfo.percentage / milestonesInfo.active
            }

            return milestonesInfo
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.text-gray {
    color: rgb(95, 95, 95);
    font-weight: bold;
}
.text-orange {
    color: orange;
    font-weight: bold;
}
.text-green {
    color: green;
    font-weight: bold;
}
.text-red {
    color: red;
    font-weight: bold;
}
</style>

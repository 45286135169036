import axios from 'axios'
import { auth } from '../firebase'

const API = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
})

API.interceptors.request.use(
    async function(config) {
        const token = auth().currentUser
            ? await auth().currentUser.getIdToken()
            : ''
        config.headers.common.Authorization = `Bearer ${token}`
        config.headers.common['Access-Control-Allow-Origin'] = '*'
        config.headers.common['Access-Control-Allow-Methods'] =
            'GET, POST, PUT, DELETE'
        config.headers.common['Access-Control-Allow-Headers'] = 'Content-Type'
        config.headers.common['cache-control'] = 'no-cache'
        config.headers.common['X-Content-Type-Options'] = 'nosniff'
        config.headers.common['X-Frame-Options'] = 'SAMEORIGIN'
        config.headers.common['Cross-Origin-Resource-Policy'] = 'same-site'
        return config
    },
    function(error) {
        return Promise.reject(error)
    }
)

export default {
    async getHeaders() {
        try {
            const response = await API.get('/authentication/headers')
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /**
     *
     * USERS ENDPOINTS
     *
     */

    async createUser(user) {
        try {
            const {
                name,
                email,
                phone,
                picture,
                role,
                phone2,
                permissions,
                language,
                process,
                signCode,
                operatorRole,
            } = user
            const response = await API.post('/users', {
                name,
                email,
                phone,
                picture,
                phone2,
                language: language,
                role,
                permissions,
                processData: process,
                signCode,
                operatorRole,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async unsubscribeFromTopic(token) {
        try {
            const response = await API.put('/users/unsubscribeFromTopic', {
                token,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async subscribeToTopic(token) {
        try {
            const response = await API.put('/users/subscribeToTopic', { token })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getUsers() {
        try {
            const response = await API.get('/users')
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getUser(id) {
        try {
            const {
                data: { user },
            } = await API.get(`/users/${id}`)
            return user
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getLiteUsers() {
        try {
            const response = await API.get('/users/lite')
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async usersChangelog() {
        try {
            const {
                data: { users },
            } = await API.get('/users/changelog')
            return users
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async usersLogs(params) {
        try {
            const { userId, startDate, endDate } = params
            const {
                data: { logs },
            } = await API.get(
                `/users/logs?userId=${userId}&startDate=${startDate}&endDate=${endDate}`
            )
            return logs
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createProject(project) {
        try {
            const {
                reference,
                sapCode,
                name,
                accountManager,
                projectManager,
                client,
                quotes,
                currency,
                country,
                state,
                city,
                address,
                collaborators,
                price,
                offer,
                generic,
                estimatedMargin,
            } = project
            const response = await API.post('/projects', {
                reference,
                sapCode,
                name,
                accountManager,
                projectManager,
                client,
                quotes,
                currency,
                country,
                state,
                city,
                address,
                collaborators,
                price,
                offer,
                generic,
                estimatedMargin,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async projectFromQuote(params) {
        try {
            const {
                reference,
                sapCode,
                name,
                accountManager,
                projectManager,
                client,
                quotes,
                currency,
                country,
                state,
                city,
                address,
                collaborators,
                price,
                offer,
                quoteId,
                estimatedMargin,
            } = params
            const response = await API.post('/projects/project-from-quote', {
                reference,
                sapCode,
                name,
                accountManager,
                projectManager,
                client,
                quotes,
                currency,
                country,
                state,
                city,
                address,
                collaborators,
                price,
                offer,
                quoteId,
                estimatedMargin,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async awardedInProject(params) {
        try {
            const response = await API.post(
                '/projects/quote-awarded-in-project',
                params
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createDocumentClient(document) {
        try {
            const { clientId, name, file, companyId } = document

            const response = await API.post('/documents/clients', {
                clientId,
                name,
                file,
                companyId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createDocumentUser(document) {
        try {
            const { name, file, userId } = document

            const response = await API.post('/documents/users', {
                name,
                file,
                userId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getOne(id) {
        try {
            const response = await API.get(`/users/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getMyInfo() {
        try {
            return await API.get('/users/myInfo')
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getOperators() {
        try {
            const {
                data: { users },
            } = await API.get('/users/operators')
            return users
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updatePermissions(userId, permissions) {
        const user = { permissions }
        try {
            const response = await API.put(`/users/permissions/${userId}`, user)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateContact(id, contact) {
        try {
            const response = await API.put(`/contacts/${id}`, contact)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getClientDocuments(id, companyId) {
        try {
            const response = await API.get(`/documents/clients/${id}`, {
                companyId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getUserDocuments(id) {
        try {
            const response = await API.get(`/documents/users/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getClients(params) {
        try {
            const response = await API.get('/clients', { params })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getLiteClients() {
        try {
            const response = await API.get('/clients/lite')
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getClient(params) {
        try {
            const response = await API.get(
                `/clients/client?clientId=${params.clientId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createClient(client) {
        try {
            Object.keys(client).forEach(key => {
                if (!client[key]) {
                    delete client[key]
                }
            })
            const response = await API.post('/clients', client)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteClient(id) {
        try {
            const response = await API.delete(`/clients/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createContact(contact) {
        try {
            const {
                name,
                picture,
                clientId,
                supplierId,
                phone,
                phone2,
                state,
                country,
                city,
                email,
                role,
                note,
            } = contact
            const response = await API.post('/contacts', {
                name,
                picture,
                phone,
                clientId,
                supplierId,
                country,
                city,
                phone2,
                state,
                email,
                role,
                note,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getContacts(params) {
        try {
            const response = await API.get(`/contacts/`, {
                params,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getContactsByClient(params) {
        try {
            const response = await API.get(
                `/contacts/contactsByClient?clientId=${params.clientId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getRoles(params) {
        try {
            const response = await API.get(`/roles/`, {
                params,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteContact(id) {
        try {
            const response = await API.delete(`/contacts/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateClient(id, client) {
        try {
            const response = await API.put(`/clients/${id}`, client)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateUser(id, user) {
        try {
            const response = await API.put(`/users/${id}`, user)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteUser(id) {
        try {
            const response = await API.delete(`/users/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteProject(id) {
        try {
            const response = await API.delete(`/projects/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateProject(id, project) {
        try {
            const {
                reference,
                sapCode,
                name,
                accountManager,
                projectManager,
                client,
                quotes,
                currency,
                country,
                state,
                city,
                address,
                collaborators,
                price,
                followUp,
                archive,
                offer,
                generic,
                exports,
                clientPortfolio,
                closingInSAP,
                estimatedMargin,
            } = project
            const response = await API.put(`/projects/${id}`, {
                reference,
                sapCode,
                name,
                accountManager,
                projectManager,
                client,
                quotes,
                currency,
                country,
                state,
                city,
                address,
                collaborators,
                price,
                followUp,
                archive,
                offer,
                generic,
                exports,
                clientPortfolio,
                closingInSAP,
                estimatedMargin,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async returnProjectFromClosing(projectId) {
        try {
            const response = await API.put(
                `/projects/return-project-from-closing?id=${projectId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createQuote(quote) {
        try {
            const {
                companyId,
                userId,
                clientId,
                notes,
                currency,
                exchange,
                files,
                leadId,
                name,
                country,
                state,
                city,
                tags,
                contacts,
                requestDate,
                invitationDate,
                promiseDate,
            } = quote
            const response = await API.post('/quotes', {
                companyId,
                userId,
                clientId,
                currency,
                leadId,
                exchange,
                files,
                status: 'OPEN',
                name,
                notes,
                country,
                state,
                city,
                tags,
                contacts,
                requestDate,
                invitationDate,
                promiseDate,
            })

            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async forBidding(lead) {
        try {
            const { companyId, leadId, promiseDate, estimatorId } = lead
            const response = await API.post('/leads/forBidding', {
                companyId,
                status: 'OPEN',
                leadId,
                promiseDate,
                estimatorId,
            })

            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getQuotes({ status, quoteId, clientId, awarded }) {
        try {
            if (quoteId) {
                return await API.get(`/quotes?quoteId=${quoteId}`)
            } else {
                return await API.get(
                    `/quotes${status ? `?status=${status}` : ''}${
                        clientId
                            ? status
                                ? `&clientId=${clientId}`
                                : `?clientId=${clientId}`
                            : ''
                    }${
                        awarded != undefined
                            ? clientId
                                ? `&awarded=${awarded}`
                                : `?awarded=${awarded}`
                            : ''
                    }`
                )
            }
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async setQuoteDates(params) {
        try {
            const { quoteId, invitationDate, requestDate, promiseDate } = params
            const {
                data: { response },
            } = await API.put(`/quotes/setDates/${quoteId}`, {
                invitationDate,
                requestDate,
                promiseDate,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async changeEstimator(params) {
        try {
            const { id, estimatorId } = params
            const {
                data: { response },
            } = await API.put(`/quotes/changeEstimator/${id}`, {
                estimatorId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async changePreconManager(params) {
        try {
            const { id, preconManagerId } = params
            const {
                data: { response },
            } = await API.put(`/quotes/changePreconManager/${id}`, {
                preconManagerId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getQuoteLogs(id) {
        try {
            const {
                data: { quotes },
            } = await API.get(`/quotes/logs?id=${id}`)
            return quotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getLiteQuotes() {
        try {
            const {
                data: { quotes },
            } = await API.get('/quotes/lite')
            return quotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getBasicInfoQuotes() {
        try {
            const {
                data: { quotes },
            } = await API.get('/quotes/import')
            return quotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getQuotesByCustomizedParameters(paramToSearch) {
        try {
            return await API.get(
                `/quotes/QuotesByCustomizedParameters?paramToSearch=${paramToSearch}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getQuote(id) {
        try {
            const response = await API.get(`/quotes/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateQuote(id, quote) {
        try {
            const keys = [
                'userId',
                'currency',
                'exchange',
                'companyId',
                'clientId',
                'collaborators',
                'followUpCollaborators',
                'commissions',
                'totalCosts',
                'commercialTerms',
                'contacts',
                'files',
                'notes',
                'name',
                'status',
                'image',
                'costExchanges',
                'country',
                'state',
                'city',
                'tags',
                'notificationId',
                'followUpDate',
            ]
            Object.keys(quote).forEach(key => {
                if (!keys.includes(key) || quote[key] === undefined) {
                    delete quote[key]
                }
            })
            const response = await API.put(`/quotes/${id}`, quote)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async modificationRequest(id, quote) {
        try {
            const keys = [
                'userId',
                'companyId',
                'clientId',
                'contacts',
                'notes',
                'name',
                'country',
                'state',
                'city',
                'tags',
                'estimatorId',
                'invitationDate',
                'requestDate',
                'promiseDate',
            ]
            Object.keys(quote).forEach(key => {
                if (!keys.includes(key) || quote[key] === undefined) {
                    delete quote[key]
                }
            })
            const response = await API.put(
                `/quotes/modification-request/${id}`,
                quote
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async arrangeQuoteCosts(id, costs) {
        try {
            const response = await API.put(`/quotes/arrange-costs/${id}`, {
                costs,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteUserDocuments(id) {
        try {
            const response = await API.delete(`/documents/users/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getSettings(params) {
        try {
            const response = await API.get(`/settings`, { params })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateSetting(id, setting) {
        const {
            costSections,
            exchanges,
            measureUnits,
            globalCurrency,
            commercialTerms,
            rejectionReasons,
            generalInfo,
            pdfImages,
            roles,
        } = setting
        try {
            const response = await API.put(`/settings/${id}`, {
                costSections,
                exchanges,
                measureUnits,
                globalCurrency,
                commercialTerms,
                rejectionReasons,
                pdfImages,
                generalInfo,
                roles,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    //SETTINGS: Update section Quotes(rejectedReasos)
    async createReason(params) {
        try {
            const response = await API.post('/settings/lost-reasons', params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateReason(params) {
        try {
            const response = await API.put('/settings/lost-reasons', params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteReason({ settingId, id }) {
        try {
            const response = await API.delete(
                `/settings/lost-reasons?settingId=${settingId}&id=${id}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getTools(params) {
        try {
            const response = await API.get(`/tools`, { params })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getCommercialImages() {
        try {
            const response = await API.get('/tools/commercialImages')
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateTools(id, tool) {
        try {
            const {
                commercialImages,
                commercialDocuments,
                technicalDocuments,
            } = tool
            const response = await API.put(`/tools/updateTools?id=${id}`, {
                commercialImages,
                commercialDocuments,
                technicalDocuments,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateCommercialImage(id, params) {
        try {
            await API.put(`/tools/updateCommercialImage?id=${id}`, params)
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async newCommercialImages(params) {
        try {
            const response = await API.post(
                `/tools/newCommercialImages`,
                params
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteCommercialImage(id) {
        try {
            await API.delete(`/tools/deleteCommercialImage?id=${id}`)
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getPreviewPDF(quoteId) {
        try {
            const {
                data: { filename },
            } = await API.get(`/send/previewPDF?quoteId=${quoteId}`)
            return filename
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendEmail(email) {
        try {
            const {
                userId,
                subject,
                to,
                text,
                cc,
                quoteId,
                attach,
                emailBody,
                notificationId,
            } = email
            const response = await API.post('/send', {
                userId,
                subject,
                to,
                text,
                cc,
                quoteId,
                attach,
                emailBody,
                notificationId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteQuote(id) {
        try {
            const response = await API.delete(`/quotes/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteCosts(quoteId, costId) {
        try {
            const response = await API.delete(
                `/cost?quoteId=${quoteId}&costId=${costId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteDocUser(userId, id) {
        try {
            const response = await API.delete(`/documents/users/${id}`, {
                userId: userId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteDocClient(id) {
        try {
            const response = await API.delete(`/documents/clients/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getCosts(id) {
        try {
            const {
                data: { costs },
            } = await API.get(`/cost/all?quoteId=${id}`)
            return costs
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createFile(id) {
        try {
            return await API.post(`/quotes/createFile`, { id })
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createCosts(idQuote, item) {
        try {
            const {
                data: { cost },
            } = await API.post(`cost`, { quoteId: idQuote, ...item })
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createImportedCosts(idQuote, item) {
        try {
            const {
                data: { cost },
            } = await API.post(`cost/imported`, { quoteId: idQuote, ...item })
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async importCosts(params) {
        try {
            const {
                data: { costs },
            } = await API.post(`cost/import`, params)
            return costs
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateCosts(quoteId, item) {
        try {
            delete item.createdOn
            delete item.updatedOn
            delete item.documentId
            const {
                data: { cost },
            } = await API.put(`cost`, {
                ...item,
                quoteId,
            })
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateHistoryCosts(idQuote, cost) {
        const {
            costs,
            totalCosts,
            file,
            currency,
            files,
            exchange,
            costExchanges,
        } = cost
        try {
            const response = await API.put(`quotes/historyCosts/${idQuote}`, {
                costs,
                file,
                totalCosts,
                currency,
                files,
                exchange,
                costExchanges,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getHistoryCosts(id) {
        try {
            const response = await API.get(`/quotes/historyCosts/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getHistoryQuotes(clientId) {
        try {
            const response = await API.get(
                `/history${clientId ? `?clientId=${clientId}` : ''}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteQuoteHistory(id) {
        try {
            const response = await API.delete(`/history/deleteQuote/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async restartQuote(id, notificationId) {
        try {
            const response = await API.post(`/history/restartQuote`, {
                id,
                notificationId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createQuoteHistory(id, status = null, rejectReason, notificationId) {
        try {
            const params = {
                id,
                rejectReason,
                notificationId,
            }
            if (status) {
                params.status = status
            }
            const response = await API.post(`/history/`, params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async markQuoteAsLost(id, status = null, rejectReason, notificationId) {
        try {
            const params = {
                id,
                rejectReason,
                notificationId,
            }
            if (status) {
                params.status = status
            }
            const response = await API.post(`/history/markAsLost`, params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async markQuoteAsAwarded(id, status = null, notificationId) {
        try {
            const params = {
                id,
                notificationId,
            }
            if (status) {
                params.status = status
            }
            const response = await API.post(`/history/markAsAwarded`, params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getArchivedVersions(id) {
        try {
            const response = await API.get(`/history/historyCosts/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getArchivedCosts(id) {
        try {
            const response = await API.get(`/history/costs/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getPdfImages(id) {
        try {
            const response = await API.get(`/settings/pdfImages/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getCommercialTerms(id) {
        try {
            const response = await API.get(`/settings/commercialTerms/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateImages(idSetting, pdfImages) {
        const { id, images } = pdfImages
        try {
            const response = await API.put(`/settings/pdfImages/${idSetting}`, {
                images,
                id,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateTerms(idSetting, commercialTerms) {
        const { id, terms } = commercialTerms
        try {
            const response = await API.put(
                `/settings/commercialTerms/${idSetting}`,
                {
                    terms,
                    id,
                }
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getSimpleQuotes(startDate, endDate) {
        try {
            const response = await API.get(
                `/quotes/simpleQuotes?startDate=${startDate}&endDate=${endDate}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getCompleteQuotes(startDate, endDate) {
        try {
            const response = await API.get(
                `/quotes/completeQuotes?startDate=${startDate}&endDate=${endDate}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createAlternativeCosts(quoteId, params) {
        try {
            const {
                data: { alternate },
            } = await API.post(`/cost/alternate`, {
                quoteId,
                ...params,
            })
            return alternate
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getSentQuotes(startDate, endDate) {
        try {
            const response = await API.get(
                `/quotes/getSentQuotes?startDate=${startDate}&endDate=${endDate}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async changeMainContact(id, params) {
        try {
            const response = await API.put(
                `/quotes/changeMainContact?id=${id}`,
                params
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getEmailBody(id) {
        try {
            const response = await API.get(`/settings/emailBody?id=${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateEmailBody(idSetting, emailBody) {
        const { id, body } = emailBody
        try {
            const response = await API.put(
                `/settings/emailBody?id=${idSetting}`,
                {
                    body,
                    id,
                }
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    // FOLLOW UP POSTS
    async createPost(params) {
        try {
            const {
                data: { post },
            } = await API.post(`/chat/post`, params)
            return post
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getPosts(chatId) {
        try {
            const {
                data: { posts },
            } = await API.get(`/chat/post?chatId=${chatId}`)
            return posts
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createEvent(params) {
        try {
            const {
                data: { event },
            } = await API.post(`/chat/event`, params)
            return event
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getEvent(eventId) {
        try {
            const {
                data: { event },
            } = await API.get(`/chat/event?eventId=${eventId}`)
            return event
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async projections(params) {
        try {
            Object.keys(params).forEach(key => {
                if (!params[key]) {
                    delete params[key]
                }
            })
            const {
                data: { projection },
            } = await API.post(`/chat/projections`, params)
            return projection
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getProjections(params) {
        try {
            const { id, startDate, endDate } = params
            const {
                data: { projections },
            } = await API.get(
                `/chat/projections${
                    id
                        ? `?id=${id}`
                        : `?startDate=${startDate}&endDate=${endDate}`
                }`
            )
            return projections
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteEvent(eventId, chatId, postId) {
        try {
            const {
                data: { response },
            } = await API.delete(
                `/quotes/event?chatId=${chatId}&eventId=${eventId}&postId=${postId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getChatInfo(id) {
        try {
            const {
                data: { chats },
            } = await API.get(`/chat/?chatId=${id}`)
            if (chats) {
                return chats[0]
            } else {
                throw new Error(`Chat not found`)
            }
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateChat(params) {
        try {
            const {
                data: { update },
            } = await API.put(`/chat/`, params)
            return update
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /**
     * Companies
     */
    async getCompanyGeneralInfo(companyId) {
        try {
            const {
                data: { company },
            } = await API.get(`company/general-info?companyId=${companyId}`)
            return company
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateCompany(companyId, params) {
        try {
            const {
                data: { company },
            } = await API.put(
                `company/general-info?companyId=${companyId}`,
                params
            )
            return company
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /**
     * Calculations
     */
    async createCalculation(calculation) {
        try {
            const keys = [
                'title',
                'currency',
                'exchange',
                'materialPrice',
                'paintPrice',
                'waste',
                'margin',
                'areas',
                'weights',
                'materials',
                'assambly',
                'packages',
                'items',
            ]
            Object.keys(calculation).forEach(key => {
                if (!keys.includes(key)) {
                    delete calculation[key]
                }
            })
            const response = await API.post('/calculations', calculation)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getCalculations() {
        try {
            return await API.get('/calculations')
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getCalculation(id) {
        try {
            const response = await API.get(`/calculations/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateCalculation(id, calculation) {
        try {
            const keys = [
                'title',
                'currency',
                'exchange',
                'materialPrice',
                'paintPrice',
                'waste',
                'margin',
                'areas',
                'weights',
                'materials',
                'assambly',
                'packages',
                'items',
            ]
            Object.keys(calculation).forEach(key => {
                if (!keys.includes(key)) {
                    delete calculation[key]
                }
            })
            const response = await API.put(`/calculations/${id}`, calculation)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateCalculationInSettings(id, calculation) {
        try {
            const keys = [
                'title',
                'currency',
                'exchange',
                'materialPrice',
                'paintPrice',
                'waste',
                'margin',
                'areas',
                'weights',
                'materials',
                'assambly',
                'packages',
                'items',
            ]
            Object.keys(calculation).forEach(key => {
                if (!keys.includes(key)) {
                    delete calculation[key]
                }
            })
            const response = await API.put(
                `/calculations/calculation-in-settings/${id}`,
                calculation
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteCalculation(id) {
        try {
            const response = await API.delete(`/calculations/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    /**
     * Projects
     */
    async getProjects({ projectId = undefined, archive = false }) {
        try {
            if (projectId) {
                const {
                    data: { projects },
                } = await API.get(`/projects?id=${projectId}`)
                return projects
            }
            const {
                data: { projects },
            } = await API.get(`/projects?archive=${archive}`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getProject(projectId) {
        try {
            const {
                data: { project },
            } = await API.get(`/projects/${projectId}`)
            return project
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getLiteProjects({ archive = false, all = false, closing = false }) {
        try {
            let query = ''
            if (archive) {
                query += `?archive=${archive}`
            }
            if (closing) {
                if (query.length > 0) {
                    query += `&closing=${closing}`
                } else {
                    query += `?closing=${closing}`
                }
            }
            if (all) {
                if (query.length > 0) {
                    query += `&all=${all}`
                } else {
                    query += `?all=${all}`
                }
            }
            const {
                data: { projects },
            } = await API.get(`/projects/lite${query}`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getLiteProjectsByUser() {
        try {
            const {
                data: { projects },
            } = await API.get(`/projects/liteByUser`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getGenericProjects() {
        try {
            const {
                data: { projects },
            } = await API.get(`/projects/generics`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getProjectQuotes(id) {
        try {
            const {
                data: { quotes },
            } = await API.get(`/projects/quotes${id ? `?id=${id}` : ''}`)
            return quotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getInformProjects() {
        try {
            const {
                data: { projects },
            } = await API.get(`/projects/inform`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    /**
     * Tasks
     */

    async createTask(params) {
        try {
            const {
                data: { task },
            } = await API.post('/tasks', params)
            return task
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteTask(params) {
        try {
            const {
                data: { task },
            } = await API.delete(
                `/tasks?id=${params.id}&assignedTo=${params.assignedTo}`
            )
            return task
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateTask(id, params) {
        try {
            Object.keys(params).map(key => {
                if (key != 'archive' && !params[key]) {
                    delete params[key]
                }
            })
            delete params['timestamp']
            delete params['createdOn']
            delete params['updatedOn']
            const {
                data: { task },
            } = await API.put(`/tasks?id=${id}`, params)
            return task
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getTasks(archive = undefined) {
        try {
            const {
                data: { tasks },
            } = await API.get(`/tasks?archive=${archive}`)
            return tasks
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getTaskChilds(taskId, assignedTo) {
        try {
            const {
                data: { taskChilds },
            } = await API.get(
                `/tasks/childs?taskId=${taskId}&assignedTo=${assignedTo}`
            )
            return taskChilds
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createTaskChild(params) {
        try {
            const {
                data: { taskChild },
            } = await API.post(`/tasks/createChild`, params)
            return taskChild
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateTaskChild(childId, params) {
        try {
            const {
                data: { taskChild },
            } = await API.put(`/tasks/updateChild?childId=${childId}`, params)
            return taskChild
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteTaskChild(taskId, childId, assignedTo) {
        try {
            await API.delete(
                `/tasks/deleteChild?taskId=${taskId}&childId=${childId}&assignedTo=${assignedTo}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getAssignedTasks(projectId = undefined, quoteId = undefined) {
        try {
            const {
                data: { tasks },
            } = await API.get(
                `/tasks/assigned?${projectId ? `projectId=${projectId}` : ''}${
                    quoteId
                        ? projectId
                            ? `&quoteId=${quoteId}`
                            : `quoteId=${quoteId}`
                        : ''
                }`
            )
            return tasks
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    // stock endpoints

    async uploadFile(file) {
        try {
            const {
                data: { msg },
            } = await API.post(`/stock/upload-file`, { file })
            return msg
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getReservedStock(projectId) {
        try {
            const {
                data: { stock },
            } = await API.get(
                `/stock/reserved-stock?${
                    projectId ? `projectId=${projectId}` : ''
                }`
            )
            return stock
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getStock() {
        try {
            const {
                data: { stock },
            } = await API.get(`/stock`)
            return stock
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getRawStock() {
        try {
            const {
                data: { stock },
            } = await API.get(`/stock/rawStock`)
            return stock
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createReserve(params) {
        try {
            const {
                data: { reserve },
            } = await API.post(`/stock/reserve`, params)
            return reserve
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getReserves(projectId = '', status = '') {
        try {
            const {
                data: { reserves },
            } = await API.get(
                `/stock/reserve?projectId=${projectId}&status=${status}`
            )
            return reserves
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateReserve(id, projectId, params) {
        try {
            const {
                data: { reserve },
            } = await API.put(
                `/stock/reserve?id=${id}&projectId=${projectId}`,
                params
            )
            return reserve
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async releaseReserve(params) {
        try {
            const {
                data: { reserve },
            } = await API.post(`/stock/release`, params)
            return reserve
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteReserve(projectId, reserveId) {
        try {
            await API.delete(
                `/stock/reserve?id=${reserveId}&projectId=${projectId}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getRequests(id) {
        try {
            const {
                data: { requests },
            } = await API.get(`/stock/request${id ? `?id=${id}` : ''}`)
            return requests
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async addRequest(params) {
        try {
            Object.keys(params).forEach(key => {
                if (!params[key]) {
                    delete params[key]
                }
            })
            await API.post(`/stock/request`, params)
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateRequest(params) {
        try {
            const {
                data: { request },
            } = await API.put(`/stock/request`, params)
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteRequest(id) {
        try {
            await API.delete(`/stock/request?id=${id}`)
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    /**
     *
     * Purchases
     */
    async purchasesChangelog(params) {
        try {
            const { start, end } = params
            const {
                data: { purchases },
            } = await API.get(`/purchase/changelog?start=${start}&end=${end}`)
            return purchases
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPurchases(params) {
        try {
            const { status, startDate, endDate } = params
            const {
                data: { purchases },
            } = await API.get(
                `/purchase?startDate=${startDate}&endDate=${endDate}&status=${status}`
            )
            return purchases
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createPurchaseRequest(params) {
        try {
            const {
                data: { request },
            } = await API.post(`/purchase`, params)
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updatePurchaseRequest(id, params) {
        try {
            const { notificationId, ...paramsToUpdate } = params
            const { data: request } = await API.put(
                `/purchase?id=${id}&notificationId=${notificationId}`,
                paramsToUpdate
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async assignRequest(id, params) {
        try {
            const { notificationId, ...body } = params
            const { data: request } = await API.put(
                `/purchase/assign?id=${id}&notificationId=${notificationId}`,
                body
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createPurchaseRequestsItem(requestId, params) {
        try {
            const {
                data: { item },
            } = await API.post(`/purchase/items?requestId=${requestId}`, params)
            return item
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updatePurchaseRequestsItem(requestId, itemId, params) {
        try {
            const {
                data: { item },
            } = await API.put(
                `/purchase/items?requestId=${requestId}&itemId=${itemId}`,
                params
            )
            return item
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async addQuoteToRequestsItem(params) {
        try {
            const {
                data: { quote },
            } = await API.post(`/purchase/quotes`, params)
            return quote
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateQuoteToRequestsItem(quoteId, params) {
        try {
            const {
                data: { quote },
            } = await API.put(`/purchase/quotes?id=${quoteId}`, params)
            return quote
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async selectQuoteToRequestsItem(quoteId, params) {
        try {
            const {
                data: { request },
            } = await API.put(
                `/purchase/quotes/selectQuote?id=${quoteId}`,
                params
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deletePurchaseRequest(requestId) {
        try {
            await API.delete(`/purchase?id=${requestId}`)
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deletePurchaseRequestQuote(requestId, itemId, quoteId) {
        try {
            await API.delete(
                `/purchase/quotes?requestId=${requestId}&itemId=${itemId}&quoteId=${quoteId}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deletePurchaseRequestItem(requestId, itemId) {
        try {
            await API.delete(
                `/purchase/items?requestId=${requestId}&itemId=${itemId}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async addEntryToRequestsItem(params) {
        try {
            const {
                data: { entry },
            } = await API.post(`/purchase/entries`, params)
            return entry
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateEntryToRequestsItem(params) {
        try {
            const { entryId, ...paramsToSave } = params
            const {
                data: { entry },
            } = await API.put(`/purchase/entries?id=${entryId}`, paramsToSave)
            return entry
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPurchasesClosed(params) {
        try {
            const {
                createdBy,
                buyer,
                projectId,
                status,
                projectManager,
            } = params
            let query = ''
            if (createdBy) {
                query += `createdBy=${createdBy}`
            }
            if (buyer) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `buyer=${buyer}&`
            }
            if (projectId) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `projectId=${projectId}&`
            }
            if (status) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `status=${status}`
            }
            if (projectManager) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `projectManager=${projectManager}`
            }
            const {
                data: { purchases },
            } = await API.get(`/purchase/closed?${query}`)
            return purchases
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPurchasesRejected() {
        try {
            const {
                data: { purchases },
            } = await API.get(`/purchase/rejected-log`)
            return purchases
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    /**
     *
     * Suppliers
     */

    async getSuppliers() {
        try {
            const {
                data: { suppliers },
            } = await API.get(`/suppliers`)
            return suppliers
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getSupplier(id) {
        try {
            const {
                data: { supplier },
            } = await API.get(`/suppliers/${id}`)
            return supplier
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createSupplier(params) {
        try {
            const {
                name,
                picture,
                country,
                city,
                state,
                language,
                currency,
                nit,
                enabled,
                sapStatus,
                note,
            } = params
            const {
                data: { supplier },
            } = await API.post('/suppliers', {
                name,
                country,
                picture,
                city,
                state,
                language,
                currency,
                nit,
                enabled,
                sapStatus,
                note,
            })
            return supplier
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateSupplier(supplierId, params) {
        try {
            const {
                data: { supplier },
            } = await API.put(`/suppliers/${supplierId}`, params)
            return supplier
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteSupplier(supplierId) {
        try {
            await API.delete(`/suppliers/${supplierId}`)
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getContactsBySupplier(params) {
        try {
            const {
                data: { contacts },
            } = await API.get(
                `/contacts/contactsBySupplier?supplierId=${params.supplierId}`
            )
            return contacts
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /**
     * Part Numbers
     */
    async createPartNumber(params) {
        try {
            const {
                data: { partNumbersCreated },
            } = await API.put(`/partNumber/`, params)

            return partNumbersCreated
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deletePartNumber(projectId, partNumberCode) {
        try {
            await API.delete(
                `/partNumber/?projectId=${projectId}&partNumberCode=${partNumberCode}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    // Products
    async getProducts() {
        try {
            const response = await API.get(`/settings/products`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteProduct(params) {
        try {
            const response = await API.delete(
                `/settings/products?settingId=${params.settingId}&productId=${params.productId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createProduct(params) {
        try {
            const {
                data: { product },
            } = await API.post(`/settings/products`, params)
            return product
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateProduct(params) {
        try {
            const { productId, ...paramsToUpdate } = params
            const {
                data: { product },
            } = await API.put(`/settings/products/${productId}`, paramsToUpdate)
            return product
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    /**
     * Part Numbers
     */
    async getPartNumbers(projectId, partNumberId) {
        try {
            const {
                data: {
                    partNumbers: { response },
                },
            } = await API.get(
                `/partNumber?projectId=${projectId}${
                    partNumberId ? `&partNumberId=${partNumberId}` : ''
                }`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /**
     * Work Orders
     */
    async createWorkOrder(params) {
        try {
            const {
                data: { workOrder },
            } = await API.post(`/workOrder`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async copyWorkOrder(params) {
        try {
            const body = {
                items: params.items != undefined ? params.items : [],
                projectId: params.projectId,
                status: params.status,
                weight: params.weight,
                ...(params.notes ? { notes: params.notes } : {}),
            }
            const {
                data: { workOrder },
            } = await API.post(`/workOrder/copy`, body)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteWorkOrder(id) {
        try {
            const {
                data: { message },
            } = await API.delete(`/workOrder?workOrderId=${id}`)
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createWorkOrderItem(params) {
        try {
            const {
                data: { item },
            } = await API.post(`/workOrder/create-item`, params)
            return item
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateWorkOrderItem(params) {
        try {
            const {
                data: { item },
            } = await API.put(`/workOrder/update-item`, params)
            return item
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateItemProcesses(params) {
        try {
            const {
                data: { item },
            } = await API.put(`/workOrder/update-item-processes`, params)
            return item
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async setBatchProcesses(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/batch-processes`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async registerProduction(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/item-production`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async markBatchReadyForPacking(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/batch-item-production`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendToNextProcess(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/item-delivery`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async receiveItems(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/receive-items`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendBatchToNextProcess(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/batch-delivery`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async registerQuality(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/item-quality`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async markBatchQuality(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/batch-quality`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async addFilesToItems(params) {
        try {
            const {
                data: { workOrder },
            } = await API.put(`/workOrder/add-files-to-items`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteWorkOrderItem(params) {
        try {
            const {
                data: { response },
            } = await API.delete(
                `/workOrder/delete-item?workOrderId=${params.workOrderId}&itemId=${params.itemId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteWorkOrderAllItems(workOrderId) {
        try {
            const {
                data: { response },
            } = await API.delete(
                `/workOrder/delete-item?workOrderId=${workOrderId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateWorkOrder(params) {
        try {
            const {
                data: { workOrder },
            } = await API.put(`/workOrder`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async closeWorkOrder(params) {
        try {
            const {
                data: { workOrder },
            } = await API.put(`/workOrder/close`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateWorkOrderNotes(params) {
        try {
            const {
                data: { workOrder },
            } = await API.put(`/workOrder/notes`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateProductionInfo(params) {
        try {
            const {
                data: { workOrder },
            } = await API.put(`/workOrder/productionInfo`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getClosedWorkOrders(params) {
        try {
            const { startDate, endDate, projectId } = params
            const {
                data: { workOrders },
            } = await API.get(
                `/workOrder/closed?${
                    startDate ? `startDate=${startDate}&` : ''
                }${endDate ? `endDate=${endDate}&` : ''}${
                    projectId ? `projectId=${projectId}` : ''
                }`
            )
            return workOrders
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /*
        PROCESSES
    */
    async getProcesses(settingId) {
        try {
            const {
                data: { processes },
            } = await API.get(`/settings/processes?settingId=${settingId}`)
            return processes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getProcess(settingId, processId) {
        try {
            const {
                data: { process },
            } = await API.get(
                `/settings/processes?settingId=${settingId}&processId=${processId}`
            )
            return process
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createProcess(params) {
        try {
            const {
                data: { process },
            } = await API.post(`/settings/processes`, params)
            return process
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateProcess(params) {
        try {
            const { processId, ...paramsToUpdate } = params
            const {
                data: { process },
            } = await API.put(
                `/settings/processes/${processId}`,
                paramsToUpdate
            )
            return process
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteProcess(params) {
        try {
            const {
                data: { message },
            } = await API.delete(
                `/settings/processes?settingId=${params.settingId}&processId=${params.processId}`
            )
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /*
        NCCodes
    */
    async getNCCodes(settingId) {
        try {
            const {
                data: { codes },
            } = await API.get(
                `/settings/quality/ncCodes?settingId=${settingId}`
            )
            return codes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getNCCode(settingId, codeId) {
        try {
            const {
                data: { code },
            } = await API.get(
                `/settings/quality/ncCodes?settingId=${settingId}&codeId=${codeId}`
            )
            return code
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createNCCode(params) {
        try {
            const {
                data: { code },
            } = await API.post(`/settings/quality/ncCodes`, params)
            return code
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateNCCode(params) {
        try {
            const { codeId, ...paramsToUpdate } = params
            const {
                data: { code },
            } = await API.put(
                `/settings/quality/ncCodes/${codeId}`,
                paramsToUpdate
            )
            return code
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteNCCode(params) {
        try {
            const {
                data: { message },
            } = await API.delete(
                `/settings/quality/ncCodes?settingId=${params.settingId}&id=${params.id}`
            )
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /*
        Finish And UC Code
    */
    async getFinishesAndUCCodes(settingId) {
        try {
            const {
                data: { finishes },
            } = await API.get(
                `/settings/finish-and-uc-code?settingId=${settingId}`
            )
            return finishes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getFinishAndUCCode(settingId, id) {
        try {
            const {
                data: { finish },
            } = await API.get(
                `/settings/finish-and-uc-code?settingId=${settingId}&id=${id}`
            )
            return finish
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createFinishAndUCCode(params) {
        try {
            const {
                data: { finish },
            } = await API.post(`/settings/finish-and-uc-code`, params)
            return finish
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateFinishAndUCCode(params) {
        try {
            const { id, ...paramsToUpdate } = params
            const {
                data: { finish },
            } = await API.put(
                `/settings/finish-and-uc-code/${id}`,
                paramsToUpdate
            )
            return finish
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteFinishAndUCCode(params) {
        try {
            const {
                data: { message },
            } = await API.delete(
                `/settings/finish-and-uc-code?settingId=${params.settingId}&id=${params.id}`
            )
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /**
     * BOM
     */
    async getBOMsChangelog({
        projectId,
        status,
        code,
        createdBy,
        deliver,
        startDate,
    }) {
        try {
            let query = ''
            if (createdBy) {
                query += `createdBy=${createdBy}`
            }
            if (code) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `code=${code}&`
            }
            if (projectId) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `projectId=${projectId}&`
            }
            if (status) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `status=${status}`
            }
            if (deliver) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `deliver=${deliver}`
            }
            if (startDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `startDate=${startDate}`
            }
            const {
                data: { boms },
            } = await API.get(`/bom/changelog?${query}`)
            return boms
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getBOMsChangelogForStock({ projectId, status, startDate }) {
        try {
            let query = ''
            if (projectId) {
                query += `projectId=${projectId}&`
            }
            if (status) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `status=${status}`
            }
            if (startDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `startDate=${startDate}`
            }
            const {
                data: { boms },
            } = await API.get(`/bom/changelog-for-stock?${query}`)
            return boms
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createBOM(params) {
        try {
            const {
                data: { boms },
            } = await API.post(`/bom`, params)
            return boms
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateBOM(params) {
        try {
            const {
                data: { boms },
            } = await API.put(`/bom`, params)
            return boms
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateBomNotes(params) {
        try {
            const {
                data: { workOrder },
            } = await API.put(`/bom/notes`, params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async hideBomALert({ workOrderId, bomId }) {
        try {
            await API.put(
                `/bom/alert?workOrderId=${workOrderId}&bomId=${bomId}`
            )
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async approveBOM(params) {
        try {
            const { id, workOrderId } = params
            const {
                data: { bom },
            } = await API.put(`/bom/approve/${id}`, {
                workOrderId,
            })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async closeBOM(params) {
        try {
            const { id, workOrderId } = params
            const {
                data: { bom },
            } = await API.put(`/bom/close/${id}`, { workOrderId })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendPartials(params) {
        try {
            const { workOrderId, bomId, requests, notificationId } = params
            const {
                data: { response },
            } = await API.put(`/bom/send-partials`, {
                workOrderId,
                bomId,
                notificationId,
                requests,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendBOM(params) {
        try {
            const {
                workOrderId,
                bomId,
                partials,
                signCode,
                requestId,
                requestedFor,
                requestedOn,
                notificationId,
            } = params
            const {
                data: { response },
            } = await API.put(`/bom/send-bom`, {
                workOrderId,
                bomId,
                partials,
                signCode,
                requestId,
                requestedFor,
                requestedOn,
                notificationId,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteBOM(params) {
        try {
            const { id, workOrderId } = params
            const {
                data: { bom },
            } = await API.delete(`/bom?id=${id}&workOrderId=${workOrderId}`)
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async rejectBOM(params) {
        try {
            const { id, workOrderId, rejectedNotes, notificationId } = params
            const {
                data: { bom },
            } = await API.put(`/bom/reject/${id}`, {
                workOrderId,
                rejectedNotes,
                notificationId,
            })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateBOMItem(params) {
        try {
            const {
                data: { bom },
            } = await API.put(`/bom/bom-item`, params)
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getProjectLogs(id) {
        try {
            const {
                data: { logs },
            } = await API.get(`/projects/logs?id=${id}`)
            return logs
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendPurchaseToReceived(id, params) {
        try {
            const { notificationId, ...paramsToUpdate } = params
            const { data: request } = await API.put(
                `/purchase/send-to-received?id=${id}&notificationId=${notificationId}`,
                paramsToUpdate
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createInform(params) {
        try {
            const {
                data: { inform },
            } = await API.post('/settings/create-inform', params)
            return inform
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getInformDetails(settingId) {
        try {
            const {
                data: { informs },
            } = await API.get(`/settings/inform-details?id=${settingId}`)
            return informs
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateInform(params) {
        try {
            const {
                data: { inform },
            } = await API.put('/settings/update-inform', params)
            return inform
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async workOrderAttachments(params) {
        try {
            const {
                data: { workOrder },
            } = await API.patch('/workOrder/attachments', params)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getStockCodes() {
        try {
            const {
                data: { codes },
            } = await API.get('/stock/stock-codes')
            return codes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async rejectPurchaseRequest(id, params) {
        try {
            const { notificationId, ...paramsToUpdate } = params
            const { data: request } = await API.put(
                `/purchase/reject?id=${id}&notificationId=${notificationId}`,
                paramsToUpdate
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getWorkOrdersByProject(projectId) {
        try {
            const {
                data: { workOrders },
            } = await API.get(
                `/workOrder/find-by-project?projectId=${projectId}`
            )
            return workOrders
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    // Invoice settings
    async getInvoiceSettings(settingId) {
        try {
            const settings = await API.get(
                `/settings/invoice?settingId=${settingId}`
            )
            return settings
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    /*
        MACHINES
    */
    async getMachines(settingId) {
        try {
            const {
                data: { machines },
            } = await API.get(`/settings/machine/?settingId=${settingId}`)
            return machines
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getMachine(settingId, machineId) {
        try {
            const {
                data: { machine },
            } = await API.get(
                `/settings/machine/?settingId=${settingId}&machineId=${machineId}`
            )
            return machine
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createInvoiceSettingItem(params) {
        try {
            const { data } = await API.post(
                `/settings/invoice/${params.itemType}`,
                {
                    name: params.name,
                    description: params.description,
                    enabledValue: params.enabledValue,
                    settingId: params.settingId,
                }
            )
            return data
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateInvoiceSettingItem(params) {
        try {
            const { data } = await API.put(
                `/settings/invoice/${params.itemType}/${params.itemId}`,
                {
                    ...(params.name ? { name: params.name } : {}),
                    ...(params.description
                        ? { description: params.description }
                        : {}),
                    ...(params.enabledValue
                        ? { enabledValue: params.enabledValue }
                        : {}),
                    settingId: params.settingId,
                }
            )
            return data
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteInvoiceSettingItem(params) {
        try {
            const response = await API.delete(
                `/settings/invoice/${params.itemType}?settingId=${params.settingId}&itemId=${params.itemId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createInvoice(params) {
        try {
            const {
                data: { invoice },
            } = await API.post(`/invoice`, params)
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getInvoices({ createdBy, projectId, status, startDate, endDate }) {
        try {
            let query = ''
            if (createdBy) {
                query += `createdBy=${createdBy}`
            }
            if (status) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `status=${status}`
            }
            if (projectId) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `projectId=${projectId}`
            }
            if (startDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `startDate=${startDate}`
            }
            if (endDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `endDate=${endDate}`
            }
            const {
                data: { invoices },
            } = await API.get(`/invoice?${query}`)
            return invoices
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async generateInvoice({
        id,
        projectId,
        attached,
        invoiceNumber,
        notificationId,
        settingId,
    }) {
        try {
            const {
                data: { invoice },
            } = await API.put(`/invoice/generate`, {
                id,
                projectId,
                attached,
                invoiceNumber,
                notificationId,
                settingId,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sentToGenerate({ id, projectId, notificationId }) {
        try {
            const {
                data: { invoice },
            } = await API.put(`/invoice/sentToGenerating`, {
                id,
                projectId,
                notificationId,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async approvalInvoice({ id, projectId, notificationId, settingId }) {
        try {
            const {
                data: { invoice },
            } = await API.put(`/invoice/approval`, {
                id,
                projectId,
                notificationId,
                settingId,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendInvoiceToClient({ id, projectId }) {
        try {
            const {
                data: { invoice },
            } = await API.put(`/invoice/send-to-client`, {
                id,
                projectId,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async invoiceAttachments({ id, projectId, files }) {
        try {
            const {
                data: { invoice },
            } = await API.patch(`/invoice/attachments`, {
                id,
                projectId,
                files,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createMachine(params) {
        try {
            const {
                data: { machine },
            } = await API.post(`/settings/machine/`, params)
            return machine
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateMachine(params) {
        try {
            const { machineId, ...paramsToUpdate } = params
            const {
                data: { machine },
            } = await API.put(`/settings/machine/${machineId}`, paramsToUpdate)
            return machine
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteMachine(params) {
        try {
            const {
                data: { message },
            } = await API.delete(
                `/settings/machine/?settingId=${params.settingId}&machineId=${params.machineId}`
            )
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async rejectInvoice({ id, projectId, notes, notificationId }) {
        try {
            const {
                data: { invoice },
            } = await API.patch(`/invoice/reject`, {
                id,
                projectId,
                notes,
                notificationId,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateInvoice({
        id,
        concept,
        category,
        projectId,
        region,
        items,
        attachments,
        credit,
        notes = undefined,
        amount,
    }) {
        try {
            const {
                data: { invoice },
            } = await API.put(`/invoice`, {
                id,
                concept,
                category,
                projectId,
                region,
                items,
                credit,
                amount,
                ...(attachments ? { attachments } : {}),
                ...(notes ? { notes } : {}),
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async rejectBomAfterApproval({ workOrderId, id, rejectedNotes }) {
        try {
            const {
                data: { bom },
            } = await API.put(`/bom/reject-bom-after-approval/${id}`, {
                workOrderId,
                rejectedNotes,
            })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async rejectBOMPartials({
        id,
        itemCode,
        workOrderId,
        rejectedNotes,
        requestId,
        showAlert,
    }) {
        try {
            const {
                data: { bom },
            } = await API.put(`/bom/reject-partials`, {
                id,
                itemCode,
                workOrderId,
                rejectedNotes,
                requestId,
                showAlert,
            })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateNotification(notificationId, readStatus) {
        try {
            const response = await API.put(
                `/users/notifications/${notificationId}`,
                { read: readStatus }
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteNotification(notificationId) {
        try {
            const {
                data: { message },
            } = await API.delete(`/users/notifications/${notificationId}`)
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async readAllNotifications() {
        try {
            const response = await API.put(`/users/notifications/readAll`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteAllNotifications() {
        try {
            const response = await API.delete(`/users/notifications/deleteAll`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async searchOperatorByCode(companyId, code) {
        try {
            const {
                data: { operator },
            } = await API.get(
                `/settings/operators/search-by-code?companyId=${companyId}&code=${code}`
            )
            return operator
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async findWorkOrderByCode(companyId, operatorCode, code) {
        try {
            const {
                data: { workOrder },
            } = await API.get(
                `/find/find-by-code?companyId=${companyId}&operatorCode=${operatorCode}&code=${code}`
            )
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async findBOMByCode(code, id) {
        try {
            const {
                data: { bom },
            } = await API.get(
                `/bom/find-by-code?code=${!id ? code : `${code}&`}${
                    id ? `id=${id}` : ''
                }`
            )
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async findWOByCode(code) {
        try {
            const {
                data: { workOrder },
            } = await API.get(`/workOrder/find-by-code?code=${code}`)
            return workOrder
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getLiteCompany(companyId) {
        try {
            const {
                data: { company },
            } = await API.get(`/company/lite/${companyId}`)
            return company
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async validateReCaptcha(token) {
        try {
            const {
                data: { response },
            } = await API.post('/company/validate-token', { token })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getChangelogInvoices({ startDate, endDate }) {
        try {
            const {
                data: { invoices },
            } = await API.get(
                `/invoice/changelog?startDate=${startDate}&endDate=${endDate}`
            )
            return invoices
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getInformInvoices({ startDate, endDate }) {
        try {
            const {
                data: { invoices },
            } = await API.get(
                `/invoice/inform?startDate=${startDate}&endDate=${endDate}`
            )
            return invoices
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async requestCreditNote(params) {
        try {
            const {
                data: { invoice },
            } = await API.put('/invoice/request-credit-note', params)
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async uploadCreditNote(params) {
        const { projectId, id, credit } = params
        try {
            const {
                data: { invoice },
            } = await API.put('/invoice/upload-credit-note', {
                projectId,
                id,
                credit,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async generateCreditNote(params) {
        const {
            projectId,
            id,
            creditPrice,
            creditRequests,
            conceptType,
        } = params
        try {
            const {
                data: { invoice },
            } = await API.put('/invoice/generate-credit-note', {
                projectId,
                id,
                creditPrice,
                creditRequests,
                conceptType,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async closeInvoice(params) {
        const { projectId, id } = params
        try {
            const {
                data: { invoice },
            } = await API.put('/invoice/close', {
                projectId,
                id,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getInvoice(number) {
        try {
            const {
                data: { invoice },
            } = await API.get(`/invoice/${number}`)
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async checkAlertNotification(params) {
        try {
            const {
                data: { notification },
            } = await API.put(`/notification/remove`, params)
            return notification
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPurchasesInform({ startDate, endDate }) {
        try {
            const {
                data: { result },
            } = await API.get(
                `/purchase/statistics?startDate=${startDate}&endDate=${endDate}`
            )
            return result
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getInformPurchases({ startDate, endDate }) {
        try {
            const {
                data: { purchases },
            } = await API.get(
                `/purchase/inform?startDate=${startDate}&endDate=${endDate}`
            )
            return purchases
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPurchasesStatusInform({ informDate }) {
        try {
            const {
                data: { statusInform },
            } = await API.get(
                `/purchase/status-inform?informDate=${informDate}`
            )
            return statusInform
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteInvoice({ id, projectId }) {
        try {
            const {
                data: { invoice },
            } = await API.delete(`/invoice?id=${id}&projectId=${projectId}`)
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updatePurchaseFiles(id, params) {
        try {
            const {
                data: { purchase },
            } = await API.put(`/purchase/files?id=${id}`, params)
            return purchase
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteInform(settingId, informId) {
        try {
            const {
                data: { inform },
            } = await API.delete(
                `/settings/delete-inform?settingId=${settingId}&informId=${informId}`
            )
            return inform
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createProformaFeature(params) {
        try {
            const {
                data: { invoice },
            } = await API.post(`/invoice/proforma-features`, params)
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateProformaFeature(id, params) {
        try {
            const {
                name,
                projectId,
                address,
                phone,
                proformaValue,
                tariffItem,
                status,
                description,
            } = params
            const {
                data: { invoice },
            } = await API.put(`/invoice/proforma-features?id=${id}`, {
                name,
                projectId,
                address,
                phone,
                proformaValue,
                tariffItem,
                status,
                description,
            })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getCanceledWorkOrders(params) {
        try {
            const { startDate, endDate } = params
            const {
                data: { workOrders },
            } = await API.get(
                `/workOrder/canceled?${
                    startDate ? `startDate=${startDate}&` : ''
                }${endDate ? `endDate=${endDate}` : ''}`
            )
            return workOrders
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getAllPurchases({ projectId }) {
        try {
            const {
                data: { purchases },
            } = await API.get(
                `/purchase/all?${projectId ? `projectId=${projectId}` : ''}`
            )
            return purchases
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteEntry({ purchaseId, itemId, id }) {
        try {
            const {
                data: { entry },
            } = await API.delete(
                `/purchase/entries?purchaseId=${purchaseId}&itemId=${itemId}&id=${id}`
            )
            return entry
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getBOMPartialDeliveries() {
        try {
            const {
                data: { partialDeliveries },
            } = await API.get(`/bom/partial-deliveries`)
            return partialDeliveries
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async restoreInvoice({ id, projectId }) {
        try {
            const {
                data: { invoice },
            } = await API.put(`/invoice/restore-invoice`, { id, projectId })
            return invoice
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async purchaseIndicators(params) {
        try {
            const { start, end } = params
            const {
                data: { inform },
            } = await API.get(`/purchase/indicators?start=${start}&end=${end}`)
            return inform
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async closeBOMPartials({ id, itemCode, workOrderId, requestId }) {
        try {
            const {
                data: { bom },
            } = await API.put(`/bom/close-partial-delivery`, {
                id,
                itemCode,
                workOrderId,
                requestId,
            })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async requestCloseBOMPartials({
        id,
        itemCode,
        workOrderId,
        notes,
        requestId,
    }) {
        try {
            const {
                data: { bom },
            } = await API.put(`/bom/request-to-close-partial-delivery`, {
                id,
                itemCode,
                workOrderId,
                notes,
                requestId,
            })
            return bom
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    // Packing list
    async createPackingList(pl) {
        try {
            const {
                projectId,
                requestedShipmentDate,
                deliveryInformation,
                notes,
                packages,
                attachments,
                selectedWorkOrders,
                selectedBOMs,
            } = pl
            const {
                data: { packingList },
            } = await API.post('/packingList', {
                projectId,
                requestedShipmentDate,
                deliveryInformation,
                notes,
                packages,
                attachments,
                selectedWorkOrders,
                selectedBOMs,
            })
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createPackage(params) {
        try {
            const {
                data: { newPackage },
            } = await API.put('/packingList/create-package', params)
            return newPackage
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deletePackage(params) {
        try {
            const {
                data: { packages },
            } = await API.put('/packingList/delete-package', params)
            return packages
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updatePackage(params) {
        try {
            const {
                data: { packages },
            } = await API.put('/packingList/update-package', params)
            return packages
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async markPLAspending(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/pending', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async markPLAsScheduled(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/scheduled', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async markPLAsReferred(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/referred', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async markPLAsShipped(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/shipped', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async markPLAsCompleted(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/completed', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async rejectPackingList(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/reject', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updatePackingList(params) {
        try {
            const {
                data: { packingList },
            } = await API.put('/packingList/', params)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPackingLists({
        createdBy,
        projectId,
        status,
        startDate,
        endDate,
    }) {
        try {
            let query = ''
            if (createdBy) {
                query += `createdBy=${createdBy}`
            }
            if (status) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `status=${status}`
            }
            if (projectId) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `projectId=${projectId}`
            }
            if (startDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `startDate=${startDate}`
            }
            if (endDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `endDate=${endDate}`
            }
            const {
                data: { packingLists },
            } = await API.get(`/packingList?${query}`)
            return packingLists
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPackingListsByProject(projectId) {
        try {
            const {
                data: { packingLists },
            } = await API.get(`/packingList/by-project?projectId=${projectId}`)
            return packingLists
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deletePackingList({ id, projectId }) {
        try {
            const {
                data: { packingList },
            } = await API.delete(`/packingList?id=${id}&projectId=${projectId}`)
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async packingListAttachments({ id, projectId, files }) {
        try {
            const {
                data: { packingList },
            } = await API.patch(`/packingList/attachments`, {
                id,
                projectId,
                files,
            })
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async packingListRemissions({ id, projectId, files }) {
        try {
            const {
                data: { packingList },
            } = await API.patch(`/packingList/remissions`, {
                id,
                projectId,
                files,
            })
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async packingListReceiptConfirmations({ id, projectId, files }) {
        try {
            const {
                data: { packingList },
            } = await API.patch(`/packingList/receiptConfirmations`, {
                id,
                projectId,
                files,
            })
            return packingList
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getBOMPartialDeliveriesForReport({ startDate, endDate }) {
        try {
            const {
                data: { partialDeliveries },
            } = await API.get(
                `/bom/partial-deliveries-for-report?startDate=${startDate}&endDate=${endDate}`
            )
            return partialDeliveries
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async purchaseReadyForPricing(id, params) {
        try {
            const { notificationId, ...paramsToUpdate } = params
            const { data: request } = await API.put(
                `/purchase/ready-for-pricing?id=${id}&notificationId=${notificationId}`,
                paramsToUpdate
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createLead(lead, notificationId) {
        try {
            const {
                companyId,
                userId,
                clientId,
                clientName,
                preconManagerId,
                collaborators,
                notes,
                currency,
                exchange,
                files,
                name,
                country,
                state,
                city,
                tags,
                contacts,
                requestDate,
                invitationDate,
            } = lead
            const response = await API.post('/leads', {
                notificationId,
                companyId,
                userId,
                clientId,
                clientName,
                collaborators,
                currency,
                exchange,
                preconManagerId,
                files,
                status: 'inProgress',
                name,
                notes,
                country,
                state,
                city,
                tags,
                contacts,
                requestDate,
                invitationDate,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createCostsDetails({ leadId, cost }) {
        try {
            const {
                data: { response },
            } = await API.put('/leads/createCosts', {
                leadId: leadId,
                ...cost,
            })

            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateCostsDetails({ leadId, costId, costDiff }) {
        try {
            const {
                data: { response },
            } = await API.put('/leads/updateCosts', {
                leadId: leadId,
                costId,
                ...costDiff,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteLeadScope(params) {
        try {
            const {
                data: { response },
            } = await API.put('/leads/deleteLeadScope', params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async importLeadsCosts(params) {
        try {
            const {
                data: { costs },
            } = await API.post(`leads/import`, params)
            return costs
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateLead(lead) {
        try {
            const {
                companyId,
                accountManager,
                preconManagerId,
                userId,
                collaborators,
                clientId,
                clientName,
                docs,
                notes,
                currency,
                exchange,
                files,
                name,
                country,
                state,
                city,
                status,
                tags,
                contacts,
                requestDate,
                invitationDate,
                id,
            } = lead

            const {
                data: { lead: response },
            } = await API.put('/leads', {
                companyId,
                accountManager,
                preconManagerId,
                userId,
                clientId,
                clientName,
                currency,
                exchange,
                collaborators,
                files,
                docs,
                status,
                name,
                notes,
                country,
                state,
                city,
                tags,
                contacts,
                requestDate,
                invitationDate,
                id,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async moveLeadFiles(params) {
        try {
            const { id, sourceFolder, destinationFolder } = params
            return API.put('/leads/move-files', {
                id,
                sourceFolder,
                destinationFolder,
            })
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async approveLead({ id }) {
        try {
            const {
                data: { lead },
            } = await API.put(`/leads/approve?id=${id}`)

            return lead
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async rejectLead({ id, rejectionReason }) {
        try {
            const {
                data: { lead },
            } = await API.delete(
                `/leads/reject?id=${id}&&rejectionReason=${rejectionReason}`
            )
            return lead
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteLead({ id }) {
        try {
            const {
                data: { lead },
            } = await API.delete(`/leads/delete?id=${id}`)
            return lead
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getLeads(params) {
        try {
            const {
                data: { leads },
            } = await API.get(`/leads/?status=${params.status}`)
            return leads
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getRejectedLeads({ startDate, endDate }) {
        try {
            let query = ''
            if (startDate) {
                query += `startDate=${startDate}`
            }
            if (endDate) {
                if (query.length > 0) {
                    query += '&'
                }
                query += `endDate=${endDate}`
            }
            const {
                data: { leads },
            } = await API.get(`/leads/rejected?${query}`)
            return leads
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getApprovedLeads() {
        try {
            const {
                data: { leads },
            } = await API.get(`/leads/approved`)
            return leads
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async approveAlternate({ quoteId, alternateId }) {
        try {
            const {
                data: { cost },
            } = await API.put(`/cost/approve-alternate`, {
                quoteId,
                alternateId,
            })
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async backToIntransit({ id, notificationId, backToInTransitNotes }) {
        try {
            const {
                data: { purchase },
            } = await API.put(`/purchase/back-to-in-transit`, {
                id,
                notificationId,
                backToInTransitNotes,
            })
            return purchase
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getChatsInFollowUp() {
        try {
            const {
                data: { chats },
            } = await API.get(`/chat/follow-up`)
            return chats
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async multipleProductionRegister(params) {
        try {
            const {
                data: { items },
            } = await API.put(`/workOrder/multiple-production-register`, params)
            return items
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    // Forecast
    async replaceForecast({ projectId, forecast }) {
        try {
            const response = await API.put(`/forecast/${projectId}`, {
                forecast,
            })
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    //Estimating plan
    async getQuotesEstimatingPlan(status) {
        try {
            const response = await API.get(
                `/quotes/estimatingQuotes?status=${status}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getCostsBigQ(startDate, endDate) {
        try {
            const response = await API.get(
                `/cost/estimating-plan?startDate=${startDate}&endDate=${endDate}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendCostToEngineering(quoteId, costId, item) {
        try {
            const {
                data: { cost },
            } = await API.put(
                `cost/forEngineering?quoteId=${quoteId}&costId=${costId}`,
                {
                    ...item,
                }
            )
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async validateInvoicesByProject(projectId) {
        try {
            const {
                data: { response },
            } = await API.get(
                `/invoice/validate-by-project?projectId=${projectId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async validateReservesByProject(projectId) {
        try {
            const {
                data: { response },
            } = await API.get(
                `/stock/validate-by-project?projectId=${projectId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async validatePurchasesByProject(projectId) {
        try {
            const {
                data: { response },
            } = await API.get(
                `/purchase/validate-by-project?projectId=${projectId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async validateWorkOrderAndPackingList(projectId) {
        try {
            const {
                data: { response },
            } = await API.get(
                `/workOrder/validate-by-project?projectId=${projectId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async sendProjectToTheClosingProcess(projectId) {
        try {
            const {
                data: { project },
            } = await API.put(`/projects/send-to-closing?id=${projectId}`)
            return project
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async closingProjects() {
        try {
            const {
                data: { projects },
            } = await API.get(`/projects/closing`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async restoreWorkOrder(id, code) {
        try {
            const {
                data: { order },
            } = await API.put(`/workOrder/restore?id=${id}&code=${code}`)
            return order
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateChatFromQuote(params) {
        try {
            const {
                data: { chat },
            } = await API.put(`/quotes/update-chat`, params)
            return chat
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateSpecialMaterial(params) {
        try {
            const { materialId, ...paramsToUpdate } = params
            const {
                data: { material },
            } = await API.put(
                `/settings/material/${materialId}`,
                paramsToUpdate
            )
            return material
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async createSpecialMaterial(params) {
        try {
            const {
                data: { response },
            } = await API.post(`/settings/material/`, params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteSpecialMaterial(params) {
        try {
            const {
                data: { response },
            } = await API.put(
                `/settings/delete-material/?settingId=${params.settingId}&materialId=${params.materialId}`
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async checkParamsForClosing(params) {
        try {
            const {
                data: { project },
            } = await API.put(`/projects/check-params-for-closing`, params)
            return project
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async requestQuotes(params) {
        try {
            const {
                data: { quote },
            } = await API.post(`/purchase/multipleQuotes`, params)
            return quote
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async reSendPurchaseQuoteEmail(params) {
        try {
            const {
                data: { quote },
            } = await API.post(`/purchase/reSendPurchaseQuoteEmail`, params)
            return quote
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async verifyEmail(email) {
        try {
            const {
                data: { user },
            } = await API.get(`/authentication/verify-email?email=${email}`)
            return user
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getPurchaseQuotes(params) {
        try {
            const {
                data: { purchaseQuotes },
            } = await API.get(
                `/purchaseQuotes/getPurchaseQuotes/?params=${params}`
            )
            return purchaseQuotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async uploadPurchaseQuote(params) {
        try {
            const API2 = axios.create({
                baseURL: process.env.VUE_APP_API_HOST,
            })
            const token = auth().currentUser
                ? await auth().currentUser.getIdToken()
                : ''
            API2.interceptors.request.use(
                async function(config) {
                    config.headers.common.Authorization = `Bearer ${token}`
                    config.headers.common['Access-Control-Allow-Origin'] = '*'
                    config.headers.common['Access-Control-Allow-Methods'] =
                        'GET, POST, PUT, DELETE'
                    config.headers.common['Access-Control-Allow-Headers'] =
                        'Content-Type'
                    config.headers.common['cache-control'] = 'no-cache'
                    return config
                },
                function(error) {
                    return Promise.reject(error)
                }
            )
            const {
                data: { purchaseQuotes },
            } = await API2.post(
                `/purchaseQuotes/uploadPurchaseQuote/?params=${params.params}`,
                {
                    paramsToUpdate: params.paramsToUpdate,
                    quotes: params.quotes,
                }
            )
            return purchaseQuotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async increasePurchaseQuoteCounter(params) {
        try {
            const {
                data: { purchaseQuotes },
            } = await API.put(
                `/purchaseQuotes/increasePurchaseQuoteCounter/?params=${params}`
            )
            return purchaseQuotes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async uploadPurchaseQuoteFile(paramsId, formData) {
        try {
            const {
                data: { purchase },
            } = await API.post(
                `/purchaseQuotes/uploadPurchaseQuoteFile/?params=${paramsId}`,
                formData
            )

            return purchase
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async verifyCode(email, code) {
        try {
            const {
                data: { user },
            } = await API.get(
                `/authentication/verify-code?email=${email}&code=${code}`
            )
            return user
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async userProcesses(userId) {
        try {
            const {
                data: { processes },
            } = await API.get(`/users/user-processes?userId=${userId}`)
            return processes
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async replaceAndDeleteUser(userId, sections) {
        try {
            await API.put(`/users/replace-and-delete`, { userId, sections })
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async closePurchaseRequest(params) {
        try {
            const { notificationId, ...paramsToUpdate } = params
            const { data: request } = await API.put(
                `/purchase/close?notificationId=${notificationId}`,
                paramsToUpdate
            )
            return request
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async removePreDesign(params) {
        try {
            const { quoteId, costId, preDesign } = params
            const {
                data: { cost },
            } = await API.put(
                `/cost/remove-preDesign?quoteId=${quoteId}&costId=${costId}`,
                { preDesign }
            )
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async addPreDesign(params) {
        try {
            const { quoteId, costId, preDesign } = params
            const {
                data: { cost },
            } = await API.put(
                `/cost/add-preDesign?quoteId=${quoteId}&costId=${costId}`,
                { preDesign }
            )
            return cost
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async addBacklog(backlog) {
        try {
            const response = await API.post(`/projects/add-backlog`, backlog)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getBacklog() {
        try {
            const response = await API.get(`/projects/get-backlog`)
            const backlog = response.data
            return backlog
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async deleteBacklog(id) {
        try {
            const response = await API.delete(`/projects/delete-backlog/${id}`)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getChats(params = {}) {
        try {
            const {
                data: { chats },
            } = await API.get(
                `/chat${params.status ? `?status=${params.status}` : ''}`
            )
            return chats
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async getQuoteRanges(idSetting) {
        try {
            const response = await API.get(
                `/settings/quote/quotes-ranges/get-ranges?idSetting=${idSetting}`
            )
            return response.data
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    async updateQuoteRanges(idSetting, range) {
        try {
            const response = await API.post(
                '/settings/quote/quotes-ranges/add-range',
                { idSetting, range }
            )
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    //  Milestones
    async getMilestones(settingId) {
        try {
            const {
                data: { milestones },
            } = await API.get(`/settings/milestone/?settingId=${settingId}`)
            return milestones
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getMilestone(settingId, milestoneId) {
        try {
            const {
                data: { milestone },
            } = await API.get(
                `/settings/milestone/?settingId=${settingId}&milestoneId=${milestoneId}`
            )
            return milestone
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createMilestone(params) {
        try {
            const {
                data: { milestone },
            } = await API.post(`/settings/milestone/`, params)
            return milestone
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateMilestone(params) {
        try {
            const { milestoneId, ...paramsToUpdate } = params
            const {
                data: { milestone },
            } = await API.put(
                `/settings/milestone/${milestoneId}`,
                paramsToUpdate
            )
            return milestone
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async deleteMilestone(params) {
        try {
            const {
                data: { message },
            } = await API.delete(
                `/settings/milestone/?settingId=${params.settingId}&milestoneId=${params.milestoneId}`
            )
            return message
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async updateMilestonesOrder(settingId, milestones) {
        try {
            await API.put('/settings/milestone/update-order', {
                settingId,
                milestones,
            })
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },

    // cost milestones
    async updateCostMilestone(params) {
        try {
            const { quoteId, costId, ...paramsToUpdate } = params
            const {
                data: { milestones },
            } = await API.put(
                `/cost/milestone?quoteId=${quoteId}&costId=${costId}`,
                paramsToUpdate
            )
            return milestones
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getProjectsBySapCode({ codes }) {
        try {
            const {
                data: { projects },
            } = await API.get(
                `/projects/get-by-sapcode?codes=${JSON.stringify(codes)}`
            )
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async createPOC(params) {
        try {
            const {
                data: { response },
            } = await API.post(`/poc`, params)
            return response
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
    async getAllPOC() {
        try {
            const {
                data: { projects },
            } = await API.get(`/poc`)
            return projects
        } catch (error) {
            const errorResponse = error.response.data
            throw new Error(errorResponse.message || errorResponse.error)
        }
    },
}

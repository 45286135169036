<template>
    <div v-if="isAllowedToRender">
        <router-view />
    </div>
</template>

<script>
import API from '@/services/api'
export default {
    name: 'App',
    data: () => ({
        userId: JSON.parse(localStorage.getItem('userId')),
    }),
    async created() {
        try {
            await API.getHeaders()
        } catch (error) {
            console.log(error)
        }
    },
    computed: {
        isAllowedToRender() {
            // Check if the current URL and origin are allowed
            const currentOrigin = window.location.origin
            const expectedOrigin = process.env.VUE_APP_CLIENT_HOST
            return currentOrigin === expectedOrigin
        },
    },
}
</script>
